import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {
  datosPedido: any[] = [];
  agregarItem(item: any) {
    this.datosPedido.push(item);
  }

  eliminarTodo() {
    this.datosPedido = [];
  }

  getDatosPedido() {
    return this.datosPedido;
  }

  constructor() { }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BASE_URL_API } from '../../environments/environment';
import { Cupon } from '../model/cupon.model';

@Injectable({
  providedIn: 'root'
})
export class CuponesService {

  constructor(private http: HttpClient) { }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const authUserItem = localStorage.getItem('auth_user');
  
    if (authUserItem) {
      const token = JSON.parse(authUserItem).token;
      if (token) {
        headers = headers.set('Authorization', `Bearer ${token}`);
      }
    }
    
    return headers;
  }



  obtenerCupones(): Observable<Cupon[]> {
    return this.http.get<Cupon[]>(`${BASE_URL_API}/productos_cupones`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  obtenerCuponPorId(id: number): Observable<Cupon> {
    return this.http.get<Cupon>(`${BASE_URL_API}/productos_cupones/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  crearCupon(cupon:Cupon): Observable<Cupon> {
    return this.http.post<Cupon>(`${BASE_URL_API}/productos_cupones`, cupon, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  actualizarCupon(id: number, cupon: Cupon): Observable<Cupon> {
    return this.http.put<Cupon>(`${BASE_URL_API}/productos_cupones/${id}`, cupon, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  eliminarCupon(id: number): Observable<Cupon> {
    return this.http.delete<Cupon>(`${BASE_URL_API}/productos_cupones/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }
  private handleError(error: HttpErrorResponse) {
    console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    console.log(error);
    return throwError('Algo malo ocurrió; por favor, inténtalo de nuevo más tarde.');
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BASE_URL_API } from '../../environments/environment';
import { Producto } from '../model/product.model';
@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(private http: HttpClient) { }


  obtenerProductos(): Observable<Producto> {
    return this.http.get<Producto>(`${BASE_URL_API}/productos/filtro/app`)
      .pipe(
        catchError(this.handleError)
      );
  }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const authUserItem = localStorage.getItem('auth_user');
  
    if (authUserItem) {
      const token = JSON.parse(authUserItem).token;
      if (token) {
        headers = headers.set('Authorization', `Bearer ${token}`);
      }
    }
    
    return headers;
  }

  getProductoCategoria(id: number): Observable<Producto> {
    return this.http.get<Producto>(`${BASE_URL_API}/productos_categorias/app/${id}`, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError));
  }

  getProductoById(id: number): Observable<Producto> {
    return this.http.get<Producto>(`${BASE_URL_API}/productos/${id}`, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError));
  }

  getAllProductosOferta(): Observable<any> {
    return this.http.get<any>(`${BASE_URL_API}/ofertas`, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError));
  }

  getAllProductosDestacados(): Observable<any> {
    return this.http.get<any>(`${BASE_URL_API}/productos/favoritos`, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError));
  }

  getAllProductosRecientes(): Observable<any> {
    return this.http.get<any>(`${BASE_URL_API}/productos/recientes`, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError));
  }

  crearProducto(producto:Producto): Observable<Producto> {
    return this.http.post<Producto>(`${BASE_URL_API}/productos`, producto, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  actualizarProducto(id: number, producto: Producto): Observable<Producto> {
    return this.http.put<Producto>(`${BASE_URL_API}/productos/${id}`, producto, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  eliminarProducto(id: number): Observable<Producto> {
    return this.http.delete<Producto>(`${BASE_URL_API}/productos/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }
  private handleError(error: HttpErrorResponse) {
    console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    console.log(error);
    return throwError('Algo malo ocurrió; por favor, inténtalo de nuevo más tarde.');
  }
}

